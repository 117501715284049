import './App.css';
import LandingPage from './Pages/LandingPage';

const App = () => {
  return (
    <div className="App">
      <LandingPage />
    </div>
  );
}

export default App;
