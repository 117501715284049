import React from 'react';
import { ChevronDown } from 'lucide-react';

const LandingPage = () => {
    return (
        <div className="bg-gray-50 text-gray-900 font-sans">
            {/* Hero Section */}
            <header className="bg-gray-300 text-gray-900 py-16 px-8">
                <div className="max-w-4xl mx-auto text-center">
                    <h1 className="text-5xl font-extrabold mb-6 leading-tight">
                        From Capitalism to Compassion: The Philanthropic Journey of Phaneesh Murthy
                    </h1>
                    <p className="text-xl font-light">
                        A transformative story of business leadership and philanthropy.
                    </p>
                </div>
            </header>

            {/* Content Section */}
            <main className="max-w-6xl mx-auto p-8">
                {/* Section: Introduction */}
                <section className="my-12">
                    <p className="text-lg leading-8 text-gray-700">
                        In the fast-paced, high-stakes realm of the technology industry, it's easy to get caught up in the relentless pursuit of innovation, market dominance, and financial success. Amidst this competitive landscape, however, there are a few remarkable individuals who have managed to not only reach the pinnacle of their professional careers but also harness their success to make a profound impact on society. Phaneesh Murthy, a renowned IT executive and current Executive Chairman at CriticalRiver, is one such leader who has embarked on a transformative journey from a captain of industry to a champion of compassion.
                    </p>
                </section>

                {/* Section: The Making of a Business Leader */}
                <section className="my-12">
                    <h2 className="text-3xl font-bold text-gray-700 mb-6">The Making of a Business Leader</h2>
                    <p className="text-lg leading-8 text-gray-700 mb-4">
                        Phaneesh Murthy's rise in the IT industry is a testament to his strategic acumen, leadership skills, and relentless pursuit of excellence. His tenure at Infosys, where he played a pivotal role in the company's global expansion, and his subsequent leadership at iGate, where he spearheaded the company's transformation, cemented his reputation as a visionary in the field.
                    </p>
                    <p className="text-lg leading-8 text-gray-700">
                        Yet, even as he navigated the cutthroat world of business, Murthy recognized that true success extended beyond the confines of the boardroom. <span className="italic">"In India, there is no culture of giving,"</span> he reflects. <span className="italic">"In the US, there's a high degree of a culture of giving and particularly giving back to one's alma mater and stuff like that."</span>
                    </p>
                </section>

                {/* Section: The Turning Point */}
                <section className="my-12 bg-gray-100 p-8 rounded-lg shadow-sm">
                    <h2 className="text-3xl font-bold text-gray-700 mb-6">The Turning Point</h2>
                    <p className="text-lg leading-8 text-gray-700 mb-4">
                        For Murthy, the turning point came when he decided to give back to his alma mater, an institute affiliated with Harvard Business School. "I had given them roughly about, I'm going to say probably about, it was in Indian Rupees, so translated to about $300,000, $250,000 to $300,000 I'd given back. And I was surprised that I was told by the institute that I was the first alumnus who had given back to that college."
                    </p>
                    <p className="text-lg leading-8 text-gray-700">
                        This realization sparked a profound shift in Murthy's perspective, setting him on a path to blend his business acumen with a growing passion for philanthropy. "So I think it was just an evolution," he muses. "You get put from one context into another context; you find that it's a good thing to do. If you have benefited from something, then you should try to give back and make sure more people can benefit."
                    </p>
                </section>

                {/* Section: The Birth of Navodaya Foundation */}
                <section className="my-12">
                    <h2 className="text-3xl font-bold text-gray-700 mb-6">The Birth of Navodaya Foundation</h2>
                    <p className="text-lg leading-8 text-gray-700 mb-4">
                        Driven by this newfound purpose, Murthy established the Navodaya Foundation, a philanthropic organization dedicated to uplifting underprivileged communities in India. The foundation's name, which means "a new dawn," reflects Murthy's commitment to bringing about transformative change in the lives of those most in need.
                    </p>
                    <p className="text-lg leading-8 text-gray-700">
                        "I believe that unless you are like some of the real billionaires who have a ton of money and can make a world impact, we have tried to minimize focus rather than our impact in specific areas," Murthy explains. "So the idea was really to focus on two things. One particularly was a little on trying to do inclusive education, so primarily girls who, in India, have been somewhat disadvantaged when coming from low-income families in terms of growth. And then the second area, which has always been a pet theme of mine, is around wildlife conservation."
                    </p>
                </section>

                {/* Section: Empowering Girls Through Education */}
                <section className="my-12 bg-gray-100 p-8 rounded-lg shadow-sm">
                    <h2 className="text-3xl font-bold text-gray-700 mb-6">Empowering Girls Through Education</h2>
                    <p className="text-lg leading-8 text-gray-700">
                        One of the Navodaya Foundation's core initiatives is promoting inclusive education for girls from disadvantaged backgrounds. Recognizing that girls in India often face significant educational barriers due to poverty and societal pressures, the foundation provides scholarships, educational resources, and support to help these girls continue their studies and pursue their aspirations.
                    </p>
                    <p className="text-lg leading-8 text-gray-700 mt-4">
                        "The girls are happy, the girls feel empowered, the girls feel enabled now," Murthy shares. "The girls are happy that they are not doing labor, that they're being treated as equals. And to me, all that is impact."
                    </p>
                </section>

                {/* Section: Protecting India's Natural Heritage */}
                <section className="my-12">
                    <h2 className="text-3xl font-bold text-gray-700 mb-6">Protecting India's Natural Heritage</h2>
                    <p className="text-lg leading-8 text-gray-700">
                        Alongside his efforts in education, Murthy has also been deeply invested in wildlife conservation, particularly in safeguarding India's endangered tiger population. The Navodaya Foundation works closely with forest departments and local communities to implement innovative strategies for tiger conservation, such as providing alternative livelihood opportunities to reduce dependence on forests and equipping anti-poaching teams with essential tools like thermal drones and camera traps.
                    </p>
                    <p className="text-lg leading-8 text-gray-700 mt-4">
                        "At the end of the day, you want to preserve the forest because they're the last lung space available in any urban areas," Murthy emphasizes. "If you don't preserve it, you just don't have any lung space left."
                    </p>
                </section>

                {/* Section: A Philosophy of Focused Impact */}
                <section className="my-12 bg-gray-100 p-8 rounded-lg shadow-sm">
                    <h2 className="text-3xl font-bold text-gray-700 mb-6">A Philosophy of Focused Impact</h2>
                    <p className="text-lg leading-8 text-gray-700">
                        The belief that focused, targeted interventions can yield the most meaningful impact is central to Murthy's philanthropic approach. Rather than spreading resources thin, he advocates concentrating efforts on specific areas where the foundation can make a tangible difference.
                    </p>
                    <p className="text-lg leading-8 text-gray-700 mt-4">
                        "Are there 50 forests in India that require help? The answer is yes, I'm sure there are. I only have the means and the ability to do one small forest," Murthy explains. "Are there millions of girls in India who require help? Yes, they do. I only have the means to potentially do 40, 50 girls at a time, and that's what I do."
                    </p>
                </section>

                {/* Section: Leading by Example */}
                <section className="my-12">
                    <h2 className="text-3xl font-bold text-gray-700 mb-6">Leading by Example</h2>
                    <p className="text-lg leading-8 text-gray-700">
                        Beyond his philanthropic endeavors, Murthy is also committed to fostering a culture of giving and social responsibility within the business community. In his current role at CriticalRiver, he actively encourages the company and its employees to contribute to charitable causes and engage in community outreach.
                    </p>
                    <p className="text-lg leading-8 text-gray-700 mt-4">
                        "I do believe that now also, even in what I'm going to call my capitalist work, which I am, maybe some people call me a compassionate capitalist, but anyway, I am a capitalist," Murthy says. "It is that when I'm working with companies and trying to restructure them, et cetera, nowadays I unabashedly tell the entrepreneur and the founders I'm working with that I'll work with you on two conditions. Condition number one is that at least 15% of the generated wealth is distributed to employees. And then the second condition is that they take 5% of whatever they make through their association with me, and they put it into any cause they believe in and support."
                    </p>
                </section>

                {/* Section: A Legacy of Compassion */}
                <section className="my-12 bg-gray-100 p-8 rounded-lg shadow-sm">
                    <h2 className="text-3xl font-bold text-gray-700 mb-6">A Legacy of Compassion</h2>
                    <p className="text-lg leading-8 text-gray-700">
                        As Phaneesh Murthy continues to lead CriticalRiver to new heights of success, his philanthropic journey through the Navodaya Foundation remains a shining example of the transformative power of compassion. By leveraging his business acumen and strategic vision to drive social change, Murthy has demonstrated that the pursuit of profit and the practice of giving need not be mutually exclusive.
                    </p>
                    <p className="text-lg leading-8 text-gray-700 mt-4">
                        Looking to the future, Murthy envisions the Navodaya Foundation expanding its reach and deepening its impact, with plans to increase its annual funding to at least a million dollars within the next couple of years. "We are not taking any external funds into it. This is all our own funds," he emphasizes. "And obviously, as we get older, it'll probably grow a little more and so on."
                    </p>
                    <p className="text-lg leading-8 text-gray-700 mt-4">
                        Through his unwavering commitment to uplifting communities and preserving India's natural heritage, Phaneesh Murthy has charted a path from capitalism to compassion, leaving an indelible mark on the lives of countless individuals. As he continues bridging the worlds of business and philanthropy, Murthy stands as a beacon of hope and inspiration, reminding us that true success is measured not just by the wealth we accumulate but by the lives we touch and the positive change we effect.
                    </p>
                </section>
            </main>
            <footer className="bg-gray-300 text-gray-900 py-8">
                <div className="container mx-auto px-4 text-center">
                    <p>&copy; 2024 Navodaya Charity. All rights reserved.</p>
                    <div className="mt-4">
                        <a href="#top" className="inline-flex items-center text-gray-900 hover:text-black hover:font-bold transition duration-300">
                            Back to Top <ChevronDown className="ml-1 transform rotate-180" size={20} />
                        </a>
                    </div>
                </div>
            </footer>
        </div>
    );
};

export default LandingPage;
